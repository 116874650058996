<template>
  <div id="bg">
    <el-row justify="start" align="middle">
      <el-col :span="3">活动开关:</el-col>
      <el-col :span="21">
        <el-switch :active-value=1 :inactive-value=0 v-model="sign_open" @click="siwtchSign" ></el-switch>
      </el-col>
      <!--      <el-col :span="6">-->
      <!--        <span>{{showReward(dayily)}}</span>-->
      <!--      </el-col>-->
    </el-row>
    <el-row justify="start" align="middle">
      <el-col :span="3">每日签到奖励:</el-col>
      <el-col :span="3">
        <el-select disabled v-model="dayily.type">
          <el-option :value=0 label="VIP天数"></el-option>
          <el-option :value=1 label="积分"></el-option>
          <el-option :value=2 label="金额"></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input v-model="dayily.reward"></el-input>
      </el-col>
      <el-col :span="6" align="center">
        <el-button @click="saveDayily" type="success">保存</el-button>
      </el-col>
<!--      <el-col :span="6">-->
<!--        <span>{{showReward(dayily)}}</span>-->
<!--      </el-col>-->
    </el-row>
    <div>
      <el-button type="primary" round @click="showDialog()">添加配置</el-button>
      <el-button type="primary" round @click="getList()">刷新</el-button>
      <el-button type="primary" @click="showPointMall()" >积分商城</el-button>
    </div>
    <el-table :data="tableData">
      <el-table-column prop="day" label="累计签到天数" align="center" ></el-table-column>
      <el-table-column prop="title" label="标题" align="center" >
        <template #default="scope">
<!--          :style="{color:scope.row.title.color}"-->
          <div v-html="getTitle(scope.row)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="奖励" align="center" >
        <template #default="scope">
          <span>{{showReward(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center" ></el-table-column>
      <el-table-column prop="addtime" label="添加时间" align="center" ></el-table-column>
      <el-table-column prop="admin" label="管理员" align="center" ></el-table-column>
      <el-table-column prop="id" width="300px">
        <template #default="scope">
          <el-button class="el-icon-edit" type="info" round @click="showDialog(scope.row)">编辑</el-button>
          <el-button class=" el-icon-delete" type="danger" round @click="delRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <el-dialog :title="dialogTitle" width="500px" :close-on-click-modal="false" v-model="showDialogVisible">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" ></el-input>
      </el-form-item>
      <el-form-item label="标题颜色" prop="color">
        <el-color-picker v-model="form.title_font"></el-color-picker>
      </el-form-item>
      <el-form-item label="标题预览" prop="title_font">
        <span :style="{color:form.title_font}">{{form.title}}</span>
      </el-form-item>
      <el-form-item label="累计天数" prop="day">
        <el-input v-model="form.day" ></el-input>
      </el-form-item>
      <el-form-item label="奖励类型" prop="type">
        <el-select v-model="form.type" >
          <el-option :value=0 label="VIP天数"></el-option>
          <el-option :value=1 label="积分"></el-option>
          <el-option :value=2 label="金额"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="奖励数量" prop="reward">
        <el-input v-model="form.reward" ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="showDialogVisible=false">取消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
      <el-form-item>
      </el-form-item>
    </el-form>
  </el-dialog>

  <el-dialog title="积分商城" width="1500px" :close-on-click-modal="false" v-model="showPointListVisible">
    <div class="search_box">
      <el-button class="el-icon-plus" @click="showMallForm()">添加</el-button>
      <el-button class="el-icon-refresh" @click="getPointList()">刷新</el-button>
    </div>
    <el-table :data="signMall.tableData" width="100%">
      <el-table-column prop="id" label="ID" align="center" ></el-table-column>
      <el-table-column prop="title" label="标题" align="center" ></el-table-column>
      <el-table-column prop="icon" label="图标" align="center" >
        <template #default="scope">
          <img :src="$store.getImage(scope.row.icon)" width="30" height="30" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" align="center" >
        <template #default="scope">
          {{getMallType(scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column prop="reward" label="奖励数值" align="center" >
        <template #default="scope">
          {{getMallReward(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="points" label="消耗积分" align="center" ></el-table-column>
      <el-table-column prop="remark" label="备注" align="center" ></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" ></el-table-column>
      <el-table-column prop="addtime" label="添加时间" align="center" ></el-table-column>
      <el-table-column prop="id" width="200px">
        <template #default="scope">
          <span class="operation el-icon-edit"  @click="showMallForm(scope.row)">编辑</span>
          <span class="operation el-icon-delete" @click="delMall(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center">
      <el-button type="info" @click="showPointListVisible=false">关闭</el-button>
    </div>
  </el-dialog>

  <el-dialog :title="signMall.formTitle" width="600px" top="5vh" :close-on-click-modal="false" v-model="signMall.formVisible">
    <el-form ref="form" :model="signMall.form" label-width="80px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="signMall.form.title" ></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="title">
        <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="uploadIcon"
            accept="image/jpeg,image/png,image/jpg"
            :before-upload="beforeAvatarUpload">
          <img v-if="signMall.form.icon_url" :src="signMall.form.icon_url" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="signMall.form.type" >
          <el-option :value=0 label="VIP天数"></el-option>
          <el-option :value=1 label="金额"></el-option>
          <el-option :value=2 label="VIP等级"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="signMall.form.type != 2" label="奖励数量" prop="reward">
        <el-input v-model="signMall.form.reward" ></el-input>
      </el-form-item >
      <el-form-item v-if="signMall.form.type == 2" label="VIP等级" prop="reward">
        <el-select v-model="signMall.form.reward" >
          <el-option v-for="item in vipList" :key="item" :value="item.Id" :label="item.Title"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消耗积分" prop="points">
        <el-input v-model="signMall.form.points" ></el-input>
      </el-form-item>
      <el-form-item label="权重" prop="weight">
        <el-input v-model="signMall.form.weight" placeholder="排序权重"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="signMall.form.remark" ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="signMall.formVisible=false">取消</el-button>
        <el-button type="primary" @click="onSubmitMall">保存</el-button>
      </el-form-item>
      <el-form-item>
      </el-form-item>
    </el-form>
  </el-dialog>

</template>

<script>
import httpClient from "@/config/httpClient";

export default {
  name: "sign",
  components: {
  },
  data() {
    return {
      sign_open:1,
      showPointListVisible:false,
      showDialogVisible:false,
      dialogTitle:"添加签到",
      dayily:{
        type:1,
        reward:10,
      }, // 每日签到奖励类型
      tableData:[
      ],
      form:{
        id:0,
        day:0,
        title:"",// 显示的标题
        title_font:"#000000",// 显示的标题
        type:0,
        reward:0,
        remark:"",
      },
      signMall:{
        types:[
          "VIP天数","金额","VIP等级"
        ],
        pager:{page:1,row:20,total:0},
        search:{
          title:""
        },
        vipList:[],
        formVisible:false,
        formTitle:"",
        form:{
          row:null,
          title:"",
          type:0,
          reward:0,
          remark:"",
          weight:0,
          points:0,
          icon:"", // 保存到数据库的值
          icon_url:"",// 显示的图片样子
        }
      }
    };
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    getTitle(row){
      return "<span style='color: "+row.title.color+"'>"+row.title.text+"</span>"
    // <span :style="scope.row.title.color" >{{scope.row.title.text}}</span>
    },
    uploadIcon(param){
      // 开始上传文件到服务器
      var _headers = {
        "is_compress":"false",
        'Name':"community",
        "is-encode":"true",
      }
      this.$common.showLoading("正在上传ICON!")
      httpClient().setHeader(_headers).upload(param.file).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          this.signMall.form.icon = res.data.filename
          this.$message({message:"图片上传成功!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    beforeAvatarUpload(file){
      this.signMall.form.icon_url =  URL.createObjectURL(file)
    },
    getMallType(type){
      return this.signMall.types[type]
    },
    getMallReward(row){
      if (row.type < 2){
        return row.reward
      }else{
        for(const vip of this.vipList){
          if (vip.Id == row.reward){
            return vip.Title
          }
        }
        return "未知VIP等级:"+row.reward
      }
    },
    delMall(row){
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        httpClient("signMallDel").post({id:row.id,google_code:value}).then((res)=>{
          if (res.code == 0){
            this.getPointList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      }).catch(() => {
      });
      // this.$confirm("删除物品: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
      //   httpClient("signMallDel").post({id:row.id,}).then((res)=>{
      //     if (res.code == 0){
      //       this.getPointList()
      //     }else{
      //       this.$message({message:res.msg,type:"error"})
      //     }
      //   })
      // })
    },
    showMallForm(row){
      this.signMall.form.title = ""
      this.signMall.form.type = 0
      this.signMall.form.reward = 0
      this.signMall.form.points = 0
      this.signMall.form.reward = ""
      this.signMall.form.icon = ""
      this.signMall.form.icon_url = ""
      this.signMall.formTitle = "添加物品"
      this.signMall.form.row = null
      if (row){
        this.signMall.form.row = row
        this.signMall.formTitle = "编辑物品"
        this.signMall.form.title = row.title
        this.signMall.form.type = row.type
        this.signMall.form.points = row.points
        this.signMall.form.reward = row.reward
        this.signMall.form.remark = row.remark
        this.signMall.form.icon = row.icon
        this.signMall.form.icon_url = this.$store.getImage(row.icon)
      }
      this.signMall.formVisible = true
    },
    onSubmitMall(){
      if (this.signMall.form.title == ""){
        this.$message({message:"请输入标题",type:"error"})
        return
      }
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {

        if(this.signMall.form.row){
          // 编辑
          httpClient("signMallEdit").post({
            type:this.signMall.form.type,
            id:this.signMall.form.row.id,
            status:0,
            points:this.signMall.form.points,
            weight:this.signMall.form.weight,
            title:this.signMall.form.title,
            reward:this.signMall.form.reward,
            remark:this.signMall.form.remark,
            icon:this.signMall.form.icon,
            google_code:value,
          }).then((res)=>{
            if (res.code == 0){
              this.signMall.formVisible = false
              this.getPointList()
            }else{
              this.$message({message:res.msg,type:"error"})
            }
          })
        }else{
          // 添加
          httpClient("signMallAdd").post({
            type:this.signMall.form.type,
            status:0,
            points:this.signMall.form.points,
            weight:this.signMall.form.weight,
            title:this.signMall.form.title,
            reward:this.signMall.form.reward,
            remark:this.signMall.form.remark,
            icon:this.signMall.form.icon,
            google_code:value
          }).then((res)=>{
            if (res.code == 0){
              this.signMall.formVisible = false
              this.getPointList()
            }else{
              this.$message({message:res.msg,type:"error"})
            }
          })
        }
      }).catch(() => {
      });

    },
    showPointMall(){
      this.showPointListVisible = true
      this.getPointList()
    },
    getPointList(){
      httpClient("signMallList").post({
        "page":this.signMall.pager.page,
        "row":this.signMall.pager.row,
        "title":this.signMall.search.title
      }).then((res)=>{
        if (res.code == 0){
          this.signMall.tableData = res.data.list
          if (this.signMall.pager.page == 1){
            this.signMall.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    saveDayily(){
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        httpClient("signEdit").post({
          "day":this.dayily.day,
          "type":this.dayily.type,
          "reward":this.dayily.reward,
          "id":0,
          "google_code":value,
        }).then((res)=>{
          if (res.code == 0){
            this.$message({message:"保存成功",type:"success"})
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      }).catch(() => {
      });
    },
    onSubmit(){
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        if (this.form.id){
          // 编辑
          httpClient("signEdit").post({
            "day":this.form.day,
            "title":this.form.title,
            "title_font":this.form.title_font,
            "type":this.form.type,
            "reward":this.form.reward,
            "remark":this.form.remark,
            "id":this.form.id,
            "google_code":value,
          }).then((res)=>{
            if (res.code == 0){
              this.showDialogVisible = false
              this.getList()
            }else{
              this.$message({message:res.msg,type:"error"})
            }
          })
        }else{
          // 添加
          httpClient("signAdd").post({
            "day":this.form.day,
            "title":this.form.title,
            "title_font":this.form.title_font,
            "type":this.form.type,
            "reward":this.form.reward,
            "remark":this.form.remark,
            "google_code":value,
          }).then((res)=>{
            if (res.code == 0){
              this.showDialogVisible = false
              this.getList()
            }else{
              this.$message({message:res.msg,type:"error"})
            }
          })
        }
      }).catch(() => {
      });

    },
    showReward(row){
      // 0VIP天数 1积分 2金额
      if (row.type == 0){
        // VIP天数
        return row.reward+"天VIP"
      }else if(row.type == 1){
        // 积分
        return row.reward+"积分"
      }else{
        // 金额
        return row.reward+"元"
      }
    },
    siwtchSign(){
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        httpClient("switchSign").post({"sign_open":this.sign_open,"google_code":value}).then((res)=>{
          if(res.code > 0){
            this.$message({message:res.msg,type:"error"})
          }else{
            this.getList()
          }
        })
      }).catch(() => {
      });

    },
    getList(){
      httpClient("signList").post().then((res)=>{
        if (res.code == 0){
          this.sign_open = res.data.sign_open
          this.vipList = res.data.vipList
          var list = []
          for(const row of res.data.list){
            if (row.id == 0){
              this.dayily = row
            }else{
              row.title = JSON.parse(row.title)
              list.push(row)
            }
          }
          this.tableData = list
          console.log(this.tableData)
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    delRow(row){
      this.$confirm("删除签到配置: " + row.day + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        // httpClient("delTag").post({"id":row.id}).then((res)=>{
        //   if(res.code == 0){
        //     this.getList()
        //   }else{
        //     this.$message({message:res.msg,type:"error"})
        //   }
        // })
      })
    },
    showDialog(row){
      this.showDialogVisible = true
      if (row){
        // 编辑
        this.dialogTitle = "编辑"
        this.form.id = row.id
        this.form.day = row.day
        this.form.type = row.type
        this.form.remark = row.remark
        this.form.reward = row.reward
        this.form.title = row.title.text
        this.form.title_font = row.title.color
      }else{
        // 添加
        this.dialogTitle = "添加"
        this.form.id = 0
        this.form.title = ""
        this.form.title_font = "#000000"
        this.form.day = 0
        this.form.type = 0
        this.form.remark = ""
        this.form.reward = 0
      }
    }
  },
};
</script>
<style>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>